export const englishLocales = {
  sidebar: {
    dashboard: 'Dashboard',
    products: 'Products',
    orders: 'Orders',
    cashier: 'Cashier',
    customers: 'Customers',
    invoices: 'Invoices',
    cashClosings: 'Cash closings',
    metrics: 'Metrics',
    vendors: 'Vendors'
  },
  helpers: {
    add: { text: 'Add' },
    charge: { text: 'Charge' },
    new: {
      male: { text: 'New' },
      female: { text: 'New' }
    },
    edit: { text: 'Edit' },
    save: { text: 'Save' },
    signOut: { text: 'Sign out' },
    signIn: { text: 'Sign in' },
    more: { text: 'More' },
    less: { text: 'Less' },
    cancel: { text: 'Cancel' },
    delete: { text: 'Delete' },
    enable: { text: 'Enable' },
    generalInformation: { text: 'General information' },
    search: { text: 'Search' },
    generate: { text: 'Generate' },
    rowsPerPage: { text: 'Rows per page' },
    of: { text: 'of' },
    continue: { text: 'Continue' },
    confirm: { text: 'Confirm' },
    download: { text: 'Download' },
    open: { text: 'Open' },
    print: { text: 'Print' }
  },
  models: {
    product: 'Product',
    salesPresentation: 'Sales presentation',
    productEquivalence: 'Equivalence',
    order: 'Order',
    customer: 'Customer',
    orderItem: 'Order item',
    invoice: 'Invoice',
    globalInvoice: 'Global invoice',
    cashClosings: 'Cash closing',
    quote: 'Quote',
    vendor: 'Vendor'
  },
  attributes: {
    product: {
      name: 'Name',
      sku: 'SKU',
      description: 'Description',
      satUnitCode: 'SAT unit code',
      satProductCode: 'SAT Product code',
      unit: 'Unit',
      subjectToIva: 'Sujeto a IVA',
      subjectToIeps: 'Sujeto a IEPS',
      ivaPercentage: 'IVA %',
      iepsPercentage: 'IEPS %'
    },
    salesPresentation: {
      name: 'Name',
      currency: 'Currency',
      baseUnitPrice: 'Unit price',
      quantityValue: 'Quantity',
      unit: 'Unit',
      sku: 'SKU',
      taxesIncluded: 'Taxes already included in unit price',
      vendors: 'Vendors'
    },
    customer: {
      firstName: 'First name',
      lastName: 'Last name',
      email: 'Email',
      phone: 'Phone',
      rfc: 'RFC',
      legalName: 'Legal name',
      cfdiUse: 'CFDI use',
      street: 'Street',
      streetNumber: 'Street number',
      additionalInfo: 'Additional info',
      zipCode: 'Zip code',
      neighborhood: 'Neighborhod',
      city: 'City',
      state: 'State',
      country: 'Country',
      taxRegime: 'Tax regime'
    },
    order: {
      customer: 'Customer'
    },
    cashClosing: {
      total: 'Total',
      customerInvoicedTotal: 'Customer invoiced total',
      invoiceableTotal: 'Invoiceable total',
      ordersCount: 'Orders count'
    },
    vendor: {
      name: 'Name',
      rfc: 'RFC',
      email: 'Email',
      street: 'Street',
      streetNumber: 'Street number',
      additionalInfo: 'Additional info',
      zipCode: 'Zip code',
      neighborhood: 'Neighborhod',
      city: 'City',
      state: 'State',
      country: 'Country'
    }
  },
  products: {
    title: 'Products'
  },
  productEquivalences: {
    title: 'Equivalence'
  },
  customers: {
    title: 'Customers'
  },
  orders: {
    title: 'Orders'
  },
  cashier: {
    orderItems: { discount: { edit: { title: 'Discount' } } }
  },
  invoices: {
    title: 'Invoices'
  },
  cashClosings: {
    title: 'Cash closings'
  },
  cashierOrders: {
    title: 'Orders'
  },
  metrics: {
    title: 'Metrics'
  },
  salesPresentations: {
    historicalPrices: {
      title: 'Historical prices'
    }
  },
  vendors: {
    title: 'Vendors'
  }
}
