const PrintIcon = ({ color = 'currentColor' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="icon">
      <path fill={color} d="M128 0C92.7 0 64 28.7 64 64v96h64V64h226.7L384
        93.3V160h64V93.3c0-17-6.7-33.3-18.7-45.3L400 18.7C388 6.7 371.7 0
        354.7 0H128zm256 352v96H128v-96h256zm64 32h32c17.7 0 32-14.3
        32-32v-96c0-35.3-28.7-64-64-64H64c-35.3 0-64 28.7-64 64v96c0 17.7 14.3 32
        32 32h32v64c0 35.3 28.7 64 64 64h256c35.3 0 64-28.7 64-64v-64zm-16-136a24 24
        0 1 1 0 48 24 24 0 1 1 0-48z"/>
    </svg>
  )
}

export { PrintIcon }