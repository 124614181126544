import { useEffect, useState } from "react"

const findPrinter = async(printerInfo) => {
  if(!printerInfo) return

  const parsedInfo = JSON.parse(printerInfo)

  const devices = await navigator.usb.getDevices();
  const device = devices.find(d => 
    d.vendorId === parsedInfo.vendorId && d.productId === parsedInfo.productId
  );
  return device
}

export const usePrinter = () => {
  const [printerDevice, setPrinterDevice] = useState(null)

  const savePrinterDevice = (device) => {
    if (device) {
      const deviceInfo = {
        vendorId: device.vendorId,
        productId: device.productId,
        productName: device.productName,
        manufacturerName: device.manufacturerName,
      };
  
      localStorage.setItem('printerInfo', JSON.stringify(deviceInfo))
      setPrinterDevice(device)
    } else {
      localStorage.removeItem('printerInfo')
      setPrinterDevice(null)
    }
  }

    // Load the printer info and find the device if it exists
    useEffect(() => {
      const loadPrinter = async () => {
        const storedPrinterInfo = localStorage.getItem("printerInfo");
        if (storedPrinterInfo) {
          const foundDevice = await findPrinter(storedPrinterInfo);
          setPrinterDevice(foundDevice);
        }
      };
  
      loadPrinter();
    }, []); 

  return { setPrinterDevice: savePrinterDevice, printerDevice }
}