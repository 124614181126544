import { useContext } from "react"
import { PrinterDeviceContext, SnackbarContext } from "../contexts"
import { configurePrinter } from "../utils"

export const usePrint = () => {
  const { setSnackbar } = useContext(SnackbarContext)
  const { printerDevice } = useContext(PrinterDeviceContext)
  
  const print = async(data) => {
    if (!printerDevice) return setSnackbar({ message: 'Impresora desconectada', alert: 'alert' })
    if (!data) return

    await configurePrinter(printerDevice)
    
    const deviceInterface = printerDevice.configuration.interfaces[0];
    const outEndpoint = deviceInterface.alternate.endpoints.find(endpoint => endpoint.direction === 'out');
  
    try {
      await printerDevice.transferOut(outEndpoint?.endpointNumber || 2, data);
    } catch (error) {
      console.error('Error writing to printer:', error);
    }
  }

  return { print }
}
