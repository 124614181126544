const LF = 0x0a;
const ESC = 0x1b;
const GS = 0x1d;

/**
 * Select justification
 * | Format   | Value    |
 * |---------|----------|
 * | ASCII   | ESC a n  |
 * | Hex     | 1B 61 n  |
 * | Decimal | 27 97 n  |
 *
 * @see https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=58
 */
export function alignment(number) {
  return [ESC, 0x61, number];
}

/**
 * Select cut mode and cut paper
 * <Function A>
 * | Format   | Value    |
 * |---------|----------|
 * | ASCII   | GS V m  |
 * | Hex     | 1D 56 m |
 * | Decimal | 29 86 m |
 *
 * <Function B, C, D>
 * | Format   | Value    |
 * |---------|-----------|
 * | ASCII   | GS V m n  |
 * | Hex     | 1D 56 m n |
 * | Decimal | 29 86 m n |
 *
 * @see https://www.epson-biz.com/modules/ref_escpos/index.php?content_id=87
 */
export function cut(m, n = null) {
  const cmd = [GS, 0x56, m];
  if (n != null) {
    cmd.push(n);
  }
  return cmd;
}

/**
 * Initialize printer
 * | Format   | Value   |
 * |---------|------- --|
 * | ASCII   | ESC @   |
 * | Hex     | 1B 40   |
 * | Decimal | 27 64   |
 *
 * @see https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=192
 */
export function initialize() {
  return [ESC, 0x40];
}

/**
 * Specify/cancel white/black inverted printing
 * | Format   | Value    |
 * |---------|----------|
 * | ASCII   | GS b n   |
 * | Hex     | 1D 42 n  |
 * | Decimal | 29 66 n  |
 *
 * @see https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=25
 */
export function invert(number) {
  return [GS, 0x42, number];
}

/**
 * Turn emphasized mode on/off
 * | Format   | Value    |
 * |---------|----------|
 * | ASCII   | ESC E n  |
 * | Hex     | 1B 45 n  |
 * | Decimal | 27 69 n  |
 *
 * @see https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=25
 */
export function textBold(number) {
  return [ESC, 0x45, number];
}

/**
 * Select character font
 * | Format   | Value    |
 * |---------|----------|
 * | ASCII   | ESC M n  |
 * | Hex     | 1B 4D n  |
 * | Decimal | 27 7 n  |
 *
 * @see https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=27
 */
export function textFont(number) {
  return [ESC, 0x4d, number];
}

/**
 * Select print mode(s)
 * | Format   | Value    |
 * |---------|----------|
 * | ASCII   | ESC ! n  |
 * | Hex     | 1B 21 n  |
 * | Decimal | 27 33 n  |
 *
 * @see https://www.epson-biz.com/modules/ref_escpos/index.php?content_id=23
 */
export function textMode(number) {
  return [ESC, 0x21, number];
}

/**
 * Select character size
 * | Format   | Value    |
 * |---------|----------|
 * | ASCII   | GS ! n  |
 * | Hex     | 1D 21 n  |
 * | Decimal | 29 33 n  |
 *
 * @see https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=34
 */
export function textSize(number) {
  return [GS, 0x21, number];
}

/**
 * Turn underline mode on/off
 * | Format   | Value    |
 * |---------|----------|
 * | ASCII   | ESC - n  |
 * | Hex     | 1B 2D n  |
 * | Decimal | 27 45 n  |
 *
 * @see https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=24
 */
export function textUnderline(number) {
  return [ESC, 0x2d, number];
}

/**
 * Select character code table
 * | Format   | Value     |
 * |---------|-----------|
 * | ASCII   | ESC t n   |
 * | Hex     | 1B 74 n   |
 * | Decimal | 27 116 n  |
 *
 * @see https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=32
 */
export function characterCodeTable(number) {
  return [ESC, 0x74, number];
}


/**
 * Select an international character set
 * | Format   | Value    |
 * |---------|----------|
 * | ASCII   | ESC R n  |
 * | Hex     | 1B 52 n  |
 * | Decimal | 27 82 n  |
 *
 * @see https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=29
 */
export function internationalCharacterSet(number) {
  return [ESC, 0x52, number];
}

export function newLine() {
  return [LF];
}