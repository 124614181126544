import { characterSet } from "./characterSet"
import { encode } from "./encode"
import {
  alignment, cut, initialize, invert, textBold, textFont, textMode, textSize, textUnderline,
  newLine
} from './commands'

export class Printer {
  constructor(characterSet = 'iso8859_2_latin2', width = 45) {
    this.characterSet = characterSet
    this.width = width
    this.commands = []
  }

  setCharacterSet(set) {
    this.characterSet = set
    this.commands.push(characterSet(set))
  }

  setTextFont(font) {
    const n = (() => {
      switch (font) {
        case 'A':
          return 0;
        case 'B':
          return 1;
        case 'C':
          return 2;
        case 'D':
          return 3;
        case 'E':
          return 4;
        case 'special-A':
          return 97;
        case 'special-B':
          return 98;
        default:
          return 0;
      }
    })();

    this.commands.push(textFont(n));
  }

  setTextBold(bold) {
    this.commands.push(textBold(bold ? 1 : 0));
  }

  setTextUnderline(underline) {
    const n = (() => {
      switch (underline) {
        case '1dot-thick':
          return 1;
        case '2dot-thick':
          return 2;
        case 'none':
          return 0;
        default:
          return 0;
      }
    })();

    this.commands.push(textUnderline(n));
  }

  setTextSize(width, height) {
    const w = (width - 1) * 16;
    const h = height - 1;
    const n = w + h;

    this.commands.push(textSize(n));
  }

  setTextNormal() {
    this.commands.push(textMode(0));
  }

  setAlign(align) {
    const n = (() => {
      switch (align) {
        case 'left':
          return 0;
        case 'center':
          return 1;
        case 'right':
          return 2;
        default:
          return 0;
      }
    })();

    this.commands.push(alignment(n));
  }

  invert(enabled) {
    this.commands.push(invert(enabled ? 1 : 0));
  }

  newLine(number = 1) {
    for(let i = 0; i < number; i++) {
      this.commands.push(newLine());
    }
  }

  cut(partial = false) {
    this.commands.push(cut(partial ? 49 : 48));
  }

  initialize() {
    this.commands.push(initialize());
  }

  line(){
    this.commands.push(this.text('-'.repeat(this.width)))
    this.commands.push(this.newLine())
  }

  text(text, align, bold, underline, invert, size, inline = false) {
    if (!text) return

    if (align != null) {
      this.commands.push(this.setAlign(align));
    }
    if (bold != null) {
      this.commands.push(this.setTextBold(bold));
    }
    if (underline != null) {
      this.commands.push(this.setTextUnderline(underline));
    }
    if (invert != null) {
      this.commands.push(invert(true));
    }
    if (size != null) {
      this.commands.push(this.setTextSize(size.width, size.height));
    }

    this.commands.push(this.encondeText(text))
  
    if(!inline) {
      this.commands.push(this.newLine())
    }

    this.resetTextFormat()
  }

  row(leftText, rightText, bold, underline, size) {
    if (!leftText && !rightText) return

    if (bold != null) {
      this.commands.push(this.setTextBold(bold));
    }
    if (underline != null) {
      this.commands.push(this.setTextUnderline(underline));
    }
    if (size != null) {
      this.commands.push(this.setTextSize(size.width, size.height));
    }

    const textLengthLimit = parseInt((this.width / 2) - 2)
    const truncatedLeftString = (leftText ?? '').substring(0, textLengthLimit)
    const truncatedRightString = (rightText ?? '').substring(0, textLengthLimit)

    const gapSpace = this.width - (truncatedLeftString.length + truncatedRightString.length)

    this.commands.push(
      this.encondeText(truncatedLeftString + ' '.repeat(gapSpace) + truncatedRightString)
    )
    this.commands.push(this.newLine())
    this.resetTextFormat()
  }

  resetTextFormat() {
    this.commands.push(this.setTextNormal())
    this.commands.push(this.setTextSize(1, 1))
    this.commands.push(this.setAlign('left'))
    this.commands.push(this.invert(false))
  }

  getData() {
    const data = this.commands.flatMap(data => data);
    return new Uint8Array(data);
  }

  clear () {
    this.commands = []
  }

  encondeText(text) {
    return Array.from(encode(text, this.characterSet));
  }
}