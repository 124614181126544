import { Printer } from '../../../packages/thermal-printer/printer'
import { toCurrency } from '../../../utils'

export const buildReceipt = (record) => {
  if (!record) return

  const printer = new Printer()
  printer.text('La casa del pastelero', 'center', true)
  printer.newLine()

  record.order_items.forEach(item => {
    printer.row(`${item.amount} x ${toCurrency(item.unit_price)}`, toCurrency(item.total))
    printer.row(`${item.product_name} ${item.sales_presentation_name}`)
    printer.newLine()
  })

  printer.newLine()
  printer.newLine()
  printer.row('Total', toCurrency(record.total))
  printer.newLine()

  printer.text('Gracias por su preferencia', 'center')
  printer.newLine(6)
  printer.cut()

  return printer.getData()
}
