import { Link, useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { LocaleContext, PrinterDeviceContext, TokenContext } from '../../contexts'
import { route } from '../../routesHelpers'
import { MXFlagIcon, PowerOffIcon, PrintIcon, USFlagIcon } from '../../icons'
import { classNames, configurePrinter, requestUsbDevice } from '../../utils'
import { translations } from '../../locales'
import { Button } from '../../components'

const Navbar = () => {
  const { token, setToken } = useContext(TokenContext)
  const { locale, setLocale } = useContext(LocaleContext)
  const { printerDevice, setPrinterDevice } = useContext(PrinterDeviceContext)
  const navigate = useNavigate()

  const signOut = (e) => {
    e.preventDefault()

    setToken(null)
    navigate(route('root'), { replace: true })
  }

  const requestPrinterDevice = async() => {
    const device = await requestUsbDevice()

    if (device) {
      await configurePrinter(device)
      setPrinterDevice(device)
    } else {
      setPrinterDevice(null)
    }
  }

  const disconnectPrinter = () => {
    if(!window.confirm('La impresora se desconectará. ¿Desea continuar?')) return

    if (!printerDevice) return

    try {
      printerDevice.close()
    } catch {
      console.log('An error has occurred closing the printer')
    } finally {
      setPrinterDevice(null)
    }
  }

  return (
    <div className='navbar bg-white sticky top-0'>
      <div className='navbar--start' />

      <div className='navbar--end'>
        {
          printerDevice ?
            <button
              type='button'
              onClick={disconnectPrinter} 
              className='flex align-center justify-center mr-4'>
              <span className='icon is-small rounded-full mr-2 bg-green-700'></span>
              <PrintIcon />
            </button>
          :
            <button
              type='button'
              onClick={requestPrinterDevice}
              className='flex align-center justify-center mr-4 px-4 py-2 hover:rounded-md hover:bg-slate-100 hover:cursor-pointer'
            >
              <span className='icon is-small rounded-full mr-2 bg-red-700'></span>
              <PrintIcon />
          </button>
        }

        <div className='locale-switch mr-4'>
          <button type='button' className={classNames('', { 'is-active': locale === 'en' })} onClick={() => { setLocale('en') }}>
            <USFlagIcon />
          </button>
          <button type='button' className={classNames('', { 'is-active': locale === 'es' })} onClick={() => { setLocale('es') }}>
            <MXFlagIcon />
          </button>
        </div>
        {
          token
            ? <Button type='button' textColor='text-red-500' backgroundColor='bg-white' className='hover-smooth-shadow' onClick={signOut}>
              <PowerOffIcon />
              </Button>
            : <Link to={route('root')} className='text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium'>
              {translations[locale].helpers.signIn.text}
            </Link>
        }
      </div>
    </div>
  )
}

export default Navbar
