import { useContext, useState } from 'react'
import {
  TableRow, TableCell, BooleanIcon, Dropdown, DropdownItem
} from '../../../components'
import { buildApiUrl, toCurrency } from '../../../utils'
import { useLocation, useNavigate } from 'react-router-dom'
import { adminRoute } from '../../../routesHelpers'
import { LocaleContext, SnackbarContext, TenantTokenContext, TokenContext } from '../../../contexts'
import { translations } from '../../../locales'
import { buildReceipt } from '../utils'
import { usePrint } from '../../../hooks'

const AdminOrderTableRow = ({ orderItem }) => {
  const [record, setRecord] = useState(orderItem)
  const { locale } = useContext(LocaleContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const { token } = useContext(TokenContext)
  const { setSnackbar } = useContext(SnackbarContext)
  const { print } = usePrint()
  const location = useLocation()
  const navigate = useNavigate()

  const generateInvoice = async () => {
    const confirmation_message = `Se generará la factura para el pedido #${record.display_number} a nombre de ${record.customer_name}. ¿Desea continuar?`
    if (!window.confirm(confirmation_message)) return

    const headers = { 'Content-Type': 'application/json' }

    if (token) {
      headers.Authorization = `Bearer ${token}`
    }

    const response = await fetch(
      buildApiUrl(`/octopus_os/t/${tenantToken}/admin/orders/${orderItem.token}/invoices`),
      { method: 'POST', headers }
    )
    const data = await response.json()

    if (response.ok) {
      setRecord(data.data)
    } else {
      setSnackbar({ message: Object.values(data.errors).join('. '), type: 'alert' })
    }
  }

  const cancelOrder = async(recordId) => {
    if (!window.confirm(`Se cancelará el pedido #${record.display_number}. ¿Desea continuar?`)) return

    const headers = { 'Content-Type': 'application/json' }

    if (token) {
      headers.Authorization = `Bearer ${token}`
    }

    const response = await fetch(
      buildApiUrl(`/octopus_os/t/${tenantToken}/admin/orders/${recordId}`),
      { method: 'DELETE', headers }
    )
    const data = await response.json()

    if (response.ok) {
      navigate(location, { state: { adminOrders: { updatedAt: Date.now() } } })
    } else {
      setSnackbar({ message: Object.values(data.errors).join('. '), type: 'alert' })
    }
  }

  return (
    <TableRow
      key={record.id} className='hover:bg-slate-100 hover:cursor-pointer'
      onDoubleClick={() => { navigate(adminRoute('showOrder', { orderToken: record.token }), { state: { previousLocation: location, modalSize: 'is-extra-large' } }) }}
    >
      <TableCell>{record.display_number}</TableCell>
      <TableCell>{record.customer_name}</TableCell>
      <TableCell>{record.ordered_at}</TableCell>
      <TableCell><BooleanIcon value={record.is_invoiced} /></TableCell>
      <TableCell className='text-right'>{toCurrency(record.price)}</TableCell>
      <TableCell className='text-right'>{toCurrency(record.discount_amount)}</TableCell>
      <TableCell className='text-right'>{toCurrency(record.total)}</TableCell>
      <TableCell>
        <Dropdown>
          <DropdownItem
            name={`${translations[locale].helpers.edit.text} ${translations[locale].models.customer.toLowerCase()}`}
            href={adminRoute('editOrderCustomer', { orderToken: record.token })}
            disabled={record.is_invoiced} modal
          />

          <DropdownItem
            name={`${translations[locale].helpers.generate.text} ${translations[locale].models.invoice.toLowerCase()}`}
            handleClick={generateInvoice} disabled={!record.is_invoiceable_for_customer || record.is_invoiced || !record.customer_id}
          />

          <DropdownItem
            name={translations[locale].helpers.print.text}
            handleClick={() => { print(buildReceipt(record))}}
          />

          <DropdownItem
            name={`${translations[locale].helpers.cancel.text} ${translations[locale].models.order.toLowerCase()}`}
            textColor='text-red-500'
            handleClick={() => cancelOrder(record.token) }
            disabled={record.is_invoiced}
          />
        </Dropdown>
      </TableCell>
    </TableRow>
  )
}

export default AdminOrderTableRow
