import { useLocation, useParams } from 'react-router-dom'
import { buildApiUrl, toCurrency } from '../../../utils'
import { SecondaryLink, Button } from '../../../components'
import { adminRoute } from '../../../routesHelpers'
import { CheckCircleIcon } from '../../../icons'
import { useContext } from 'react'
import { LocaleContext, TenantTokenContext } from '../../../contexts'
import { useFetch } from '../../../hooks/useFetch'
import { buildReceipt } from '../utils'
import { usePrint } from '../../../hooks'

const AdminCashierOrderCompleted = () => {
  const location = useLocation()
  const { locale } = useContext(LocaleContext)
  const { orderToken } = useParams()
  const { tenantToken } = useContext(TenantTokenContext)
  const { print } = usePrint()

  const url = buildApiUrl(`/octopus_os/t/${tenantToken}/pos/orders/${orderToken}`, { locale })
  const { data } = useFetch(url)
  const record = data.data

  const handlePrint = () => {
    print(buildReceipt(record))
  }

  if (!record) return <p>Cargando...</p>

  return (
    <>
      <div className='flex flex-1 bg-white rounded-3xl p-4 shadow-smooth items-center justify-center flex-col'>
        <div className='text-center mb-12'>
          <CheckCircleIcon color='green' className='w-24 md:w-44 m-auto' />
          <p className='text-2xl mt-4'><b>¡Pedido completado!</b></p>
        </div>

        <div className='md:flex md:justify-evenly md:gap-4 w-full'>
          <div className='text-center mb-4 md:mb-0'>
            <p className='text-5xl'>{toCurrency(location.state?.amountReceived)}</p>
            <p className='text-xl text-gray-500'>Cliente pagó con</p>
          </div>

          <div className='text-center'>
            <p className='text-5xl'>{toCurrency(location.state?.amountReceived - location.state?.total)}</p>
            <p className='text-xl text-gray-500'>Cambio</p>
          </div>
        </div>

        <div className='flex mt-8 gap-12'>
          <SecondaryLink href={adminRoute('cashier')}>
            Regresar a caja
          </SecondaryLink>

          <Button onClick={handlePrint} backgroundColor='bg-transparent' textColor='text-blue-600'>
            Imprimir ticket
          </Button>
        </div>
      </div>
    </>
  )
}

export default AdminCashierOrderCompleted
