import { useContext } from 'react'
import { Button, Header } from '../../../components'
import { translations } from '../../../locales'
import { LocaleContext, SnackbarContext, TenantTokenContext, TokenContext } from '../../../contexts'
import { buildApiUrl, toCurrency } from '../../../utils'
import { useNavigate } from 'react-router-dom'
import { adminRoute } from '../../../routesHelpers'
import { usePrint } from '../../../hooks'
import { buildReceipt } from '../utils'

const AdminCashierSavedOrder = ({ order, onDestroyed = () => {} }) => {
  const { tenantToken } = useContext(TenantTokenContext)
  const { token } = useContext(TokenContext)
  const { locale } = useContext(LocaleContext)
  const { setSnackbar } = useContext(SnackbarContext)
  const { print } = usePrint()
  const navigate = useNavigate()

  const openOrder = () => {
    navigate(adminRoute('cashier'), { state: { order } })
  }

  // TODO: Fix. Snackbar not working
  const destroyRecord = async (orderToken) => {
    if (!window.confirm('¿Desea eliminar este pedido?')) return

    try {
      const response = await fetch(
        buildApiUrl(`/octopus_os/t/${tenantToken}/pos/orders/${orderToken}`),
        { method: 'DELETE', headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } }
      )

      if (response.ok) return onDestroyed(orderToken)

      setSnackbar({ meesage: 'Error. Pedido no ha posido ser eliminado', type: 'alert' })
    } catch {
      setSnackbar({ meesage: 'Error. Pedido no ha posido ser eliminado', type: 'alert' })
    }
  }

  return (
    <div className='rounded-xl w-full md:w-auto shadow-lg border p-4'>
      <Header title={`#${order.display_number}`} fontSize='text-xl' />

      <p>{toCurrency(order.total)}</p>
      <p>{order.customer_name}</p>

      <div className='flex mt-4 justify-between'>
        <Button
          backgroundColor='transparent' textColor='text-red-600' textSize='text-xs' padding='p-0'
          onClick={() => { destroyRecord(order.token) }}
        >
          {translations[locale].helpers.delete.text}
        </Button>

        <Button
          backgroundColor='transparent' textColor='text-black-600' textSize='text-xs' padding='p-0'
          onClick={() => { print(buildReceipt(order)) }}
        >
          {translations[locale].helpers.print.text}
        </Button>

        <Button backgroundColor='transparent' textColor='text-black-600' padding='p-0' onClick={openOrder}>
          {translations[locale].helpers.open.text}
        </Button>
      </div>
    </div>
  )
}

export default AdminCashierSavedOrder
